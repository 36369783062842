import { create } from 'zustand'
import { getCategories, getLiveList, getBjList } from '@/api/poongtoday'

interface Categories {
  [key: string]: string
}

export default create<{
  categories: Categories
  liveChannels: any
  bjList: any
  initialized: boolean
  fetch: any
}>(set => ({
  categories: {},
  liveChannels: [],
  bjList: null,
  initialized: false,
  fetch: async () => {
    const [categories, liveChannels, bjList] = await Promise.all([getCategories(), getLiveList(), getBjList()])
    set({ categories: categories?.DATA?.[0] ?? {}, liveChannels, bjList, initialized: true })
  },
}))
