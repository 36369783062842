import styles from './main.module.scss'
import { Link } from 'react-router-dom'
import Summary from '@components/pages/main/Summary.tsx'
import RecentBoard from '@components/pages/main/RecentBoard.tsx'
import TopRankingGroup from '@components/pages/main/TopRankingGroup.tsx'
import Search from './Search'
import useBannerStore from '@/stores/banner'
import useAnnouncementStore from '@/stores/announcement'
import { ModalPopup } from '@/components/popups/ModalPortal'
import { AlertModal } from '@/components/popups/Alert'
import Popup from '@/components/popups/Popup'
import { useEffect, useState } from 'react'
import { notShowSeconds } from '../consts'

export default function Main() {
  const banners = useBannerStore(state => state.banners)
  const { popups } = useAnnouncementStore()
  const [showPopup, setShowPopup] = useState(
    localStorage.getItem('noPopup') && parseInt(localStorage.getItem('noPopup')!) + notShowSeconds > Date.now()
      ? false
      : true,
  )
  const [dontShowToday, setDontShowToday] = useState(false)

  useEffect(() => {
    setShowPopup(
      localStorage.getItem('noPopup') && parseInt(localStorage.getItem('noPopup')!) + notShowSeconds > Date.now()
        ? false
        : true,
    )
  }, [])

  function MobileBanner() {
    const data = banners.filter((b: any) => b.placement === '모바일 배너')
    const index = Math.floor(Math.random() * data.length)
    const available = data && data.length > 0
    return (
      <a
        href={available ? data[index].url : 'https://uploads.poong.today/files/poongtoday_ad_0611.pdf'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={available ? data[index].image : 'https://uploads.poong.today/public/ad_mobile.jpg'}
          alt={'ad'}
          className={styles.mMainBannerArea}
        />
      </a>
    )
  }

  function BjBanner(placement: string, order: number) {
    const data = banners.filter((b: any) => b.placement === placement)
    const index = Math.floor(Math.random() * data.length)
    const available = data && data.length > 0
    return (
      <li>
        <Link
          to={available ? data[index].url : 'https://uploads.poong.today/files/poongtoday_ad_0611.pdf'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={available ? data[index].image : `https://uploads.poong.today/public/ad_pc_footer${order}.jpg`}
            alt="live"
          />
        </Link>
      </li>
    )
  }

  function MainBanner() {
    const data = banners.filter((b: any) => b.placement === '메인 가로 배너')
    const index = Math.floor(Math.random() * data.length)
    const available = data && data.length > 0
    return (
      <div className={styles.mainBannerAd}>
        <span>
          <a
            href={available ? data[index].url : 'https://uploads.poong.today/files/poongtoday_ad_0611.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={available ? data[index].image : 'https://uploads.poong.today/public/ad_pc_main.jpg'}
              alt="main banner"
            />
          </a>
        </span>
      </div>
    )
  }

  function SideBanner() {
    const data = banners.filter((b: any) => b.placement === '세로 배너')
    const index = Math.floor(Math.random() * data.length)
    const available = data && data.length > 0
    return (
      <div className={styles.mainWingBannerArea}>
        <span className={styles.leftBanner}>
          <a
            href={available ? data[index].url : 'https://uploads.poong.today/files/poongtoday_ad_0611.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={available ? data[index].image : 'https://uploads.poong.today/public/ad_pc_side.jpg'}
              alt="main banner"
            />
          </a>
        </span>
        <span className={styles.rightBanner}>
          <a
            href={available ? data[index].url : 'https://uploads.poong.today/files/poongtoday_ad_0611.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={available ? data[index].image : 'https://uploads.poong.today/public/ad_pc_side.jpg'}
              alt="main banner"
            />
          </a>
        </span>
      </div>
    )
  }

  return (
    <div className="contents-area">
      {/* 메인 검색  */}
      <Search />
      <Summary />

      {/* 메인 광고 배너 */}
      <div>
        {MainBanner()}
        {SideBanner()}
      </div>

      {/* 랭킹 */}
      <TopRankingGroup />

      {/* 메인 모바일 배너 영역} */}
      {MobileBanner()}

      {/* 광고 flexTv 라이브 방송 목록 */}
      <div className={styles.mainLiveArea}>
        <div className={styles.mainLive}>
          <ul>
            {BjBanner('BJ 광고 배너 A', 1)}
            {BjBanner('BJ 광고 배너 B', 2)}
            {BjBanner('BJ 광고 배너 C', 3)}
            {BjBanner('BJ 광고 배너 D', 4)}
          </ul>
        </div>
      </div>

      <RecentBoard />

      {MobileBanner()}

      <ModalPopup>
        {showPopup && popups.length > 0 && (
          <AlertModal
            type="confirm"
            title=""
            onClose={() => {
              setShowPopup(false)
              if (dontShowToday) [localStorage.setItem('noPopup', String(Date.now()))]
            }}
          >
            <Popup popupData={popups} dontShowToday={dontShowToday} setDontShowToday={setDontShowToday} />
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
