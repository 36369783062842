import { getBjListMonth } from '@/api/bjpt'
import styles from '@components/pages/broadcast/BroadcastView/broadcastView.module.scss'
import { useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { PERIOD_TYPE } from '../../consts'
import { today, startYear } from '@/utils/date.ts'
import MonthSelector from '@/components/elements/selectors/MonthSelector'

export default function BroadcastMonthStat({ id, show }: { id: string; show: boolean }) {
  const [historyDate, setHistoryDate] = useState(today())
  const selectedMonthStat = useRef(null) as any
  const [selectedMonth, setSelectedMonth] = useState<{ key: string; value: string }[]>([
    { key: String(historyDate.getMonth()), value: String(historyDate.getMonth() + 1).padStart(2, '0') },
  ])
  const [selectedYear, setSelectedYear] = useState<{ key: string; value: string }[]>([
    { key: String(historyDate.getFullYear()), value: String(historyDate.getFullYear()) },
  ])

  const {} = useQuery(
    ['bjRanking', historyDate],
    () => getBjListMonth(historyDate.getFullYear(), historyDate.getMonth() + 1),
    {
      onSuccess: (data: any) => {
        selectedMonthStat.current = data.ranking?.find((bj: any) => bj.i === id)
      },
    },
  )

  function historyDateButtonHandler(periodToAdd: PERIOD_TYPE, change: number) {
    const newDate = new Date(historyDate)
    if (periodToAdd === PERIOD_TYPE.MONTH) {
      newDate.setMonth(change)
    } else if (periodToAdd === PERIOD_TYPE.YEAR) {
      newDate.setFullYear(change)
    }
    if (startYear <= newDate.getFullYear() && newDate <= today()) {
      setHistoryDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
    } else if (newDate.getFullYear() === today().getFullYear() && newDate > today()) {
      newDate.setMonth(today().getMonth())
      setHistoryDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
    }
  }

  return (
    <div className={show ? `${styles.summaryBroadcast} ${styles.on}` : `${styles.summaryBroadcast}`}>
      <div className={styles.viewTitle}>방송 기록 요약</div>
      <MonthSelector
        date={historyDate}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        dateButtonHandler={historyDateButtonHandler}
        smaller={true}
      />
      <div className={styles.summary}>
        <ul>
          <li>
            누적 별풍선
            <span>{selectedMonthStat.current ? selectedMonthStat.current.b.toLocaleString() : 0}</span>
          </li>
          <li>
            시급(풍)
            <span>
              {selectedMonthStat.current && selectedMonthStat.current.t !== 0
                ? Math.round((selectedMonthStat.current.b * 60 * 60) / selectedMonthStat.current.t).toLocaleString()
                : 0}
            </span>
          </li>
          <li>
            최고 시청자
            <span>{selectedMonthStat.current ? selectedMonthStat.current.m.toLocaleString() : 0}</span>
          </li>
          <li>
            누적 시청자
            <span>{selectedMonthStat.current ? selectedMonthStat.current.v.toLocaleString() : 0}</span>
          </li>
          <li>
            방송 시간
            {selectedMonthStat.current ? (
              <span>
                {Math.floor(selectedMonthStat.current.t / 3600)}시간{' '}
                {Math.floor((selectedMonthStat.current.t % 3600) / 60)}분
              </span>
            ) : (
              <span>0시간 0분</span>
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}
