import styles from './pointDetails.module.scss'
import { useState } from 'react'
import { ModalPopup } from '../../../popups/ModalPortal'
import { AlertModal } from '../../../popups/Alert'
import useUserStore from '@/stores/user'
import BoardSignin from '@components/pages/community/shared/BoardSignin.tsx'
import PointGuide from '@components/popups/PointGuide'
import { format } from 'date-fns'
import Paging from '@/components/elements/Paging'
import { pointsPageLimit } from '../../community/shared/consts'
import MorePoints from '../../pointshop/MorePoints'
import PointAside from './PointAside'

export default function PointDetails() {
  const [page, setPage] = useState(1)
  const user = useUserStore(state => state.user)
  const lastPage = !user || user.points.length === 0 ? 1 : Math.ceil(user.points.length / pointsPageLimit)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="contents-area">
      <div className={styles.titleArea}>
        <h2>포인트 내역</h2>
      </div>

      <PointAside />

      <div className={styles.pointContsArea}>
        <div className={styles.contsArea}>
          <div className="community-area" style={{ marginTop: '0' }}>
            <div className="community-box">
              <div className="community-body">
                <ul className={`body ${styles.pointDetailsList}`}>
                  {!user || user.points.length === 0 ? (
                    <li className={`row list-null ${styles.ptNull}`}>포인트 내역이 없습니다.</li>
                  ) : (
                    user.points
                      .sort((p1: any, p2: any) => p2.id - p1.id)
                      .slice((page - 1) * pointsPageLimit, page * pointsPageLimit)
                      .map((point: any) => (
                        <li className="row" key={point.id}>
                          <div>
                            <span className={styles.chip}>
                              {point.points > 0 && <div className="chip blue natural">포인트 획득</div>}
                              {point.points < 0 && !point.info.includes('구매') && (
                                <div className="chip red natural">포인트 차감</div>
                              )}
                              {point.points < 0 && point.info.includes('구매') && (
                                <div className="chip red natural">포인트 사용</div>
                              )}
                            </span>
                            {point.points > 0 ? (
                              <span className={styles.point}>+ {Math.abs(point.points).toLocaleString()}</span>
                            ) : (
                              <span className={styles.point}>- {Math.abs(point.points).toLocaleString()}</span>
                            )}
                          </div>
                          <div>
                            <span className={styles.subject}>{point.info}</span>
                            <span className={styles.date}>
                              {format(new Date(point.createdAt), 'yyyy-MM-dd kk:mm:ss')}
                            </span>
                          </div>
                        </li>
                      ))
                  )}
                </ul>
              </div>
            </div>
            <Paging page={page} setPage={setPage} lastPage={lastPage} />
          </div>
        </div>

        <div className={styles.aside}>
          <BoardSignin showShop={true} showWrite={false} notLoginText="포인트 내역은 로그인 후 가능합니다" />
          <button type="button" className={styles.btnGuide} onClick={() => setShowModal(true)}>
            포인트획득 가이드
          </button>
          <MorePoints />
        </div>
      </div>

      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="" onClose={setShowModal}>
            <PointGuide />
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
