import { create } from 'zustand'
import { fetchAllHiddenBJs, fetchAllHiddenDonors } from '@/api/hidden'

export default create<{
  hiddenBjs: any
  hiddenDonors: any
  fetch: any
}>(set => ({
  hiddenBjs: [],
  hiddenDonors: [],
  fetch: async () => {
    const [hiddenBjs, hiddenDonors] = await Promise.all([fetchAllHiddenBJs(), fetchAllHiddenDonors()])
    set({
      hiddenBjs: hiddenBjs.map((bj: any) => bj.userID),
      hiddenDonors: hiddenDonors.map((d: any) => d.userID),
    })
  },
}))
