import styles from './platform.module.scss'
import ECharts from 'echarts-for-react'

export default function PlatformChart({ data, dailyPoints }: { data: any; dailyPoints: number }) {
  const colors = ['#73A2FF', '#00FFA3', '#C299FF']
  const bMax = Math.max(data.bTodayMax, data.bYdayMax)
  const vMax = Math.max(data.vTodayMax, data.vYdayMax)
  const cMax = Math.max(data.cTodayMax, data.cYdayMax)
  const startIndex = data.tList.length - dailyPoints

  function getData(seriesName: string, index: number) {
    if (seriesName === '별풍선') {
      return data.bList[index].toLocaleString()
    } else if (seriesName === '시청자') {
      return data.vList[index].toLocaleString()
    } else if (seriesName === '방송 수') {
      return data.cList[index].toLocaleString()
    } else {
      return 0
    }
  }

  const options = {
    color: colors,
    tooltip: {
      type: 'item',
      trigger: 'axis',
      formatter: (params: any) => {
        if (params.length === 0) {
          return ''
        } else {
          const { dataIndex } = params[0]
          let tooltipTxt = `${data.tList[startIndex + dataIndex]}<br />`
          for (let i = 0; i < params.length; i++) {
            tooltipTxt += `${params[i].marker} ${params[i].seriesName}: ${getData(params[i].seriesName, startIndex + dataIndex)}`
            if (i < params.length - 1) {
              tooltipTxt += '<br />'
            }
          }
          return tooltipTxt
        }
      },
      textStyle: {
        color: 'var(--pt-grayscale-50)',
        fontSize: 10,
      },
      backgroundColor: 'var(--pt-grayscale-700)',
      borderRadius: 8,
      padding: 10,
      transitionDuration: 0,
      showContent: true,
      borderWidth: 0,
    },
    legend: {
      data: ['별풍선', '시청자', '방송 수'],
      bottom: 5,
      textStyle: {
        color: 'var(--pt-grayscale-50)',
        fontSize: 12,
      },
      icon: 'circle',
      itemGap: 30,
      x: 'center',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.tList.slice(startIndex).map((t: string) => t.substring(10)),
    },
    yAxis: [
      {
        type: 'value',
        max: 1.2,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#2A2C32',
          },
        },
      },
    ],
    series: [
      {
        name: '별풍선',
        type: 'bar',
        showSymbol: false,
        data: data.bList.slice(startIndex).map((b: number) => b / bMax),
        barMaxWidth: '20%',
        itemStyle: {
          borderRadius: [10, 10, 10, 10],
        },
      },
      {
        name: '시청자',
        type: 'line',
        showSymbol: false,
        data: data.vList.slice(startIndex).map((v: number) => v / vMax),
        lineStyle: {
          width: 2,
        },
      },
      {
        name: '방송 수',
        type: 'line',
        showSymbol: false,
        data: data.cList.slice(startIndex).map((c: number) => c / cMax),
        lineStyle: {
          width: 2,
        },
      },
    ],
  }

  return (
    <div className={styles.chartArea}>
      <div style={{ width: '100%' }}>
        <ECharts option={options} style={{ width: '100%', height: '400px' }} opts={{ renderer: 'svg' }} />
      </div>
    </div>
  )
}
