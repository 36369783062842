import axios from 'axios'

export async function getBjHistory(id: string) {
  return axios
    .get(`https://bjapi.afreecatv.com/api/${id}/station`)
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function getBjDetail(id: string) {
  return axios
    .get(`https://bjapi.afreecatv.com/api/${id}/station/detail`)
    .then(response => response.data)
    .catch(error => console.error(error))
}
