import styles from './mypage.module.scss'
import { Link } from 'react-router-dom'
import { signout } from '@/api/auth'

export default function MyPageHeader({ user, fetch }: { user: any; fetch: any }) {
  return (
    <div className={styles.mypageUser}>
      {user ? (
        <div className={styles.name}>
          <h2>{user.nick} 님</h2>
        </div>
      ) : (
        <div className={styles.name}>
          <h2>로그인 해주세요</h2>
        </div>
      )}
      {user ? (
        <div className={styles.bottomMenu}>
          <div>
            <span className={styles.pointLabel}>MY POINT</span>
            <span className={styles.pointValue}>
              {user.points.map((point: any) => point.points).reduce((p1: number, p2: number) => p1 + p2, 0)}
            </span>
          </div>
          <div className={styles.buttonMenu}>
            <Link to="/my/points" className={styles.pointLink}>
              포인트 내역 보기
            </Link>
            <button
              type="button"
              className={styles.logout}
              onClick={() => {
                signout().then(async () => {
                  await fetch()
                  window.location.href = '/'
                })
              }}
            >
              로그아웃
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
