import styles from './broadcastView.module.scss'
import { getBjDetail, getBjHistory } from '@/api/afreeca.ts'
import useHiddenStore from '@/stores/hidden'
import { useQuery } from 'react-query'
import { useContext, useRef, useState } from 'react'
import { format } from 'date-fns'
import { ko } from 'date-fns/locale'
import { getBjListDay, getBjListMonth } from '@/api/bjpt.ts'
import BroadcastDonorRanking from '@components/pages/broadcast/BroadcastView/BroadcastDonorRanking.tsx'
import BroadcastChart from '@components/pages/broadcast/BroadcastView/BroadcastChart.tsx'
import BroadcastMonthStat from '@components/pages/broadcast/BroadcastView/BroadcastMonthStat.tsx'
import { BJ_CATEGORY } from '../../consts'
import { today } from '@/utils/date'
import { Link } from 'react-router-dom'
import { getTopPct, getTier } from '@/helpers/bj/helper'
import { Tooltip } from 'react-tooltip'
import { ThemeContext } from '@/context/ThemeContext'

export default function BroadcastView({ id }: { id: string }) {
  const { theme } = useContext(ThemeContext)
  const liveDonation = useRef(0)
  const { hiddenDonors, hiddenBjs } = useHiddenStore()
  const [broadCategory, setBroadCategory] = useState<BJ_CATEGORY>(BJ_CATEGORY.BJ_INFO)
  const tier = useRef(0)

  const {
    data: bjHistory,
    isLoading: bjHistoryLoading,
    isError: bjHistoryError,
  } = useQuery('bjHistory', () => getBjHistory(id))

  const {
    data: bjDetail,
    isLoading: bjDetailLoading,
    isError: bjDetailError,
  } = useQuery('bjDetail', () => getBjDetail(id))

  const {} = useQuery(
    ['currentMonthBjStats'],
    () => {
      const currentDate = today()
      return getBjListMonth(currentDate.getFullYear(), currentDate.getMonth() + 1)
    },
    {
      onSuccess: (data: any) => {
        if (!data.ranking) return

        const ranking = data.ranking.findIndex((bj: any) => bj.i === id)
        const currentMonthRank = ranking + 1
        tier.current = currentMonthRank === 0 ? getTier(1) : getTier(currentMonthRank / data.ranking.length)
      },
    },
  )

  const { isLoading: currentDayBjStatsLoading, isError: currentDayBjStatsError } = useQuery(
    ['currentDayBjStats'],
    () => {
      const currentDate = today()
      return getBjListDay(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
    },
    {
      onSuccess: (data: any) => {
        const currentBj = data.ranking?.filter((bj: any) => bj.i === id) ?? []
        if (currentBj.length === 0) {
          liveDonation.current = 0
        } else {
          liveDonation.current = currentBj[0].b
        }
      },
    },
  )

  if (
    bjHistoryLoading ||
    bjHistoryError ||
    bjDetailLoading ||
    bjDetailError ||
    currentDayBjStatsLoading ||
    currentDayBjStatsError ||
    !bjHistory ||
    hiddenBjs.includes(id)
  ) {
    return
  }

  return (
    <div className="contents-area">
      <div className={styles.bjDataArea}>
        <div className={styles.name}>
          <div className={`${styles.img} ${styles.af}`}>
            <img src={bjHistory.profile_image} alt="thumn" />
          </div>
          {/* <div className={`${styles.img} ${styles.ch}`}>
            <img src="/images/contents/img_sample_bj.png" alt="thumn" />
          </div> */}
          <div className={styles.nickname}>
            <div className={styles.nick}>
              {bjHistory.station.user_nick}
              <span className={styles.af}>
                <img src="/images/contents/ico_logo_afreecatv_lg.svg" alt="afreecatv" />
              </span>
              {/* <span className={styles.ch}><img src="/images/contents/ico_logo_chzzk_lg.svg" alt="chzzk" /></span> */}
            </div>
            <div className={styles.chip}>
              {bjHistory.is_partner_bj ? <span className={`chip blue`}>파트너 BJ</span> : <></>}
              {bjHistory.is_best_bj ? <span className={`chip green`}>베스트 BJ</span> : <></>}
              {bjHistory.station.groups.filter((group: any) => group.info.group_name === '프로게이머BJ').length !==
              0 ? (
                <span className={`chip gray`}>프로게이머 BJ</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className={styles.state}>
          <div className={styles.stateBox}>
            <span>별풍선</span>
            <span>
              Tier {tier.current + ' '}
              <img
                data-tooltip-id="tier-info"
                data-tooltip-content={`Tier ${tier.current}: 상위 ${getTopPct(tier.current)}%`}
                src={`/images/contents/${theme}/ico_info.svg`}
                alt="information"
              />
              <Tooltip className={styles.tierTooltip} place="bottom" id="tier-info" />
            </span>
          </div>
          <div className={styles.stateBox}>
            <span>팔로워</span>
            <span>{bjHistory.station.upd.fan_cnt.toLocaleString()}</span>
          </div>
        </div>
      </div>

      {/* 모바일일 경우 탭
        방송 정보 >> 라이브, 방송기록 요약 활성화
        히스토리요약 >> 모바일 히스토리, 히스토리 요약 활성화
        팬 랭킹 >> 랭킹 게시판 활성화
      */}
      <div className={styles.moTabArea}>
        <div className="tab-type-horizon">
          <ul>
            <li>
              <a
                className={broadCategory === BJ_CATEGORY.BJ_INFO ? 'active' : ''}
                onClick={() => setBroadCategory(BJ_CATEGORY.BJ_INFO)}
              >
                방송 정보
              </a>
            </li>
            <li>
              <a
                className={broadCategory === BJ_CATEGORY.HISTORY ? 'active' : ''}
                onClick={() => setBroadCategory(BJ_CATEGORY.HISTORY)}
              >
                히스토리
              </a>
            </li>
            <li>
              <a
                className={broadCategory === BJ_CATEGORY.FAN_RANKING ? 'active' : ''}
                onClick={() => setBroadCategory(BJ_CATEGORY.FAN_RANKING)}
              >
                팬 랭킹
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.broadViewWrap}>
        {/* aside 영역 */}
        <div className={styles.aside}>
          {/* 라이브방송 >> 모바일일 경우 className >> on 추가 시 활성화  */}
          {bjHistory.broad ? (
            <div className={broadCategory === BJ_CATEGORY.BJ_INFO ? `${styles.live} ${styles.on}` : `${styles.live}`}>
              <div className={styles.viewTitle}>
                <span className={`chip lg red`}>LIVE</span>실시간 방송
              </div>
              <div className={styles.preview}>
                <div className={styles.thumnail}>
                  <span className={styles.thum}>
                    <Link
                      to={`//play.afreecatv.com/${id}/${bjHistory.broad.broad_no}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={`//liveimg.afreecatv.com/${bjHistory.broad.broad_no}.gif`} alt="live" />
                    </Link>
                  </span>
                  <span className={styles.txt}>{bjHistory.broad.broad_title}</span>
                </div>
              </div>
              <div className={styles.status}>
                <div>
                  별풍선
                  <span>{liveDonation.current.toLocaleString()}</span>
                </div>
                <div>
                  시청자
                  <span>{bjHistory.broad.current_sum_viewer.toLocaleString()}</span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* 방송기록요약 >> 모바일일 경우 className >> on 추가 시 활성화   */}
          <BroadcastMonthStat id={id} show={broadCategory === BJ_CATEGORY.BJ_INFO} />

          {/* 차트영역 모바일일 이면서 className >> on 추가 시 활성화 */}
          <BroadcastChart id={id} mobile={true} show={broadCategory === BJ_CATEGORY.HISTORY} />

          {/* 히스토리요약 모바일일 경우 className >> on 추가 시 활성화 */}
          <div
            className={
              broadCategory === BJ_CATEGORY.HISTORY
                ? `${styles.summaryHistory} ${styles.on}`
                : `${styles.summaryHistory}`
            }
          >
            <div className={styles.viewTitle}>히스토리 요약</div>
            <div className={styles.summary}>
              <ul>
                <li>
                  <span>방송국 개설일</span>
                  <span>{format(new Date(bjHistory.station.jointime), 'yyyy.MM.dd', { locale: ko })}</span>
                </li>
                <li>
                  <span>최근 방송일</span>
                  <span>{format(new Date(bjHistory.station.broad_start), 'yyyy.MM.dd(E)', { locale: ko })}</span>
                </li>
                <li>
                  <span>즐겨찾기</span>
                  <span>{bjHistory.station.upd.fan_cnt.toLocaleString()}</span>
                </li>
                <li>
                  <span>구독자</span>
                  <span>{bjHistory.subscription.count.toLocaleString()}</span>
                </li>
                <li>
                  <span>팬클럽</span>
                  <span>{bjDetail.count.fanclub.toLocaleString()}</span>
                </li>
                <li>
                  <span>서포터</span>
                  <span>{bjDetail.count.supporter.toLocaleString()}</span>
                </li>
                <li>
                  <span>누적 시청자</span>
                  <span>{bjHistory.station.upd.total_view_cnt.toLocaleString()}</span>
                </li>
                <li>
                  <span>누적 UP수</span>
                  <span>{bjHistory.station.upd.total_ok_cnt.toLocaleString()}</span>
                </li>
                <li>
                  <span>누적 방송 시간</span>
                  <span>
                    {Math.floor(bjHistory.station.total_broad_time / 86400)}일{' '}
                    {Math.floor((bjHistory.station.total_broad_time % 86400) / 3600)}
                    시간
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* contents 영역 pc일 경우 활성화 */}
        <div className={styles.contsWrap}>
          <BroadcastChart id={id} mobile={false} show={true} />

          {/* 팬 랭킹 모바일일 경우 className >> on 추가 시 활성화  */}
          <BroadcastDonorRanking id={id} show={broadCategory === BJ_CATEGORY.FAN_RANKING} hiddenIDs={hiddenDonors} />
        </div>
      </div>
    </div>
  )
}
