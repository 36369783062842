import { getBjHistory } from '@/api/afreeca'
import { getMonthlyBjBroadStats } from '@/api/poongtoday'
import styles from '@components/pages/broadcast/BroadcastView/broadcastView.module.scss'
import { useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import ECharts from 'echarts-for-react'
import { CHART_CATEGORY } from '../../consts'
import { today } from '@/utils/date.ts'

export default function BroadcastChart({ id, mobile, show }: { id: string; mobile: boolean; show: boolean }) {
  const currentDate = today()
  const [chartCategory, setChartCategory] = useState<CHART_CATEGORY>(CHART_CATEGORY.DONATION)
  const chartData = useRef<any[]>([])
  const chartStartRef = useRef(today()) // 현재 차트의 시작 날짜
  const chartStart = useRef(1) // 차트의 시작 포인트
  const chartEnd = useRef(100)
  const [chartStartDate, setChartStartDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)) // API 에 쓰는 차트 시작 날짜
  const { data: bjHistory } = useQuery('bjHistory', () => getBjHistory(id))
  const maxBarWidth = '30%'

  const {} = useQuery(
    ['chartData', chartStartDate],
    () => getMonthlyBjBroadStats(id, chartStartDate.getFullYear(), chartStartDate.getMonth() + 1),
    {
      onSuccess: (data: any) => {
        if (chartStartRef.current > chartStartDate) {
          const oldLength = chartData.current.length
          let newLength = 0
          if (!data.error && data.d) {
            chartData.current = [...data.d, ...chartData.current]
            newLength = data.d.length
          } else {
            const daysInMonth = new Date(chartStartDate.getFullYear(), chartStartDate.getMonth() + 1, 0).getDate()
            const newList = []
            for (let i = 0; i < daysInMonth; i++) {
              newList.push({ d: 0, b: 0, m: 0, v: 0, u: 0, f: 0, s: 0, fc: 0, sp: 0 })
            }
            chartData.current = [...newList, ...chartData.current]
            newLength = daysInMonth
          }
          chartStartRef.current = chartStartDate
          chartStart.current = chartData.current.length === newLength ? 1 : (newLength / chartData.current.length) * 100
          chartEnd.current =
            chartData.current.length === newLength
              ? 100
              : ((oldLength * (chartEnd.current / 100) + newLength) / chartData.current.length) * 100
        }
      },
    },
  )

  function getMin() {
    if (chartCategory === CHART_CATEGORY.FAVOURITE || chartCategory === CHART_CATEGORY.SUBSCRIBERS) {
      return null
    } else {
      return 0
    }
  }

  function getXaxis() {
    return chartData.current.map((_: any, index: number) => {
      const currentDate = new Date(chartStartRef.current)
      currentDate.setDate(currentDate.getDate() + index)
      return format(currentDate, 'yyyy-MM-dd')
    })
  }

  function getSeriesData() {
    if (chartCategory === CHART_CATEGORY.DONATION) {
      return {
        data: chartData.current.map((day: any) => day.b),
        type: 'bar',
        barMaxWidth: maxBarWidth,
      }
    } else if (chartCategory === CHART_CATEGORY.CONCURRENT_VIEWER) {
      return {
        data: chartData.current.map((day: any) => day.m),
        type: 'bar',
        barMaxWidth: maxBarWidth,
      }
    } else if (chartCategory === CHART_CATEGORY.FAVOURITE) {
      const fan = [] as number[]
      let start = bjHistory.station.upd.fan_cnt
      for (let i = chartData.current.length - 1; i >= 0; i--) {
        start -= chartData.current[i].f
        fan.unshift(start)
      }
      return {
        data: fan,
        type: 'line',
        areaStyle: {},
        barMaxWidth: maxBarWidth,
      }
    } else if (chartCategory === CHART_CATEGORY.UP) {
      return {
        data: chartData.current.map((day: any) => day.u),
        type: 'bar',
        barMaxWidth: maxBarWidth,
      }
    } else if (chartCategory === CHART_CATEGORY.SUBSCRIBERS) {
      const subscribers = [] as number[]
      let start = bjHistory.subscription.count
      for (let i = chartData.current.length - 1; i >= 0; i--) {
        start -= chartData.current[i].s
        subscribers.unshift(start)
      }
      return {
        data: subscribers,
        type: 'line',
        areaStyle: {},
        barMaxWidth: maxBarWidth,
      }
    }
  }

  const options = {
    legend: {
      show: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          precision: 0,
          backgroundColor: 'var(--pt-grayscale-600)',
          fontSize: 11,
        },
        lineStyle: {
          type: 'dashed',
        },
      },
      backgroundColor: 'var(--pt-grayscale-700)',
      padding: 10,
      textStyle: {
        color: 'var(--pt-grayscale-50)',
        fontSize: 12,
      },
      transitionDuration: 0,
      showContent: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: getXaxis(),
    },
    yAxis: {
      scale: true,
      type: 'value',
      axisLine: { show: false },
      min: getMin(),
      splitNumber: 4,
      boundaryGap: ['10%', '20%'],
    },
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0],
        start: chartStart.current,
        end: chartEnd.current,
      },
      {
        type: 'slider',
        xAxisIndex: 0,
        filterMode: 'empty',
      },
    ],
    series: getSeriesData(),
  }

  function handleDataZoom(param: any) {
    if (param.batch && param.batch[0]) {
      chartStart.current = param.batch[0].start
      chartEnd.current = param.batch[0].end
      if (param.batch[0].start < 1) {
        const newDate = new Date(chartStartRef.current)
        newDate.setMonth(newDate.getMonth() - 1)
        setChartStartDate(newDate)
      }
    } else {
      chartStart.current = param.start
      chartEnd.current = param.end
      if (param.start < 1) {
        const newDate = new Date(chartStartRef.current)
        newDate.setMonth(newDate.getMonth() - 1)
        setChartStartDate(newDate)
      }
    }
  }

  function getClassname() {
    if (mobile) {
      if (show) {
        return `${styles.chart} ${styles.chartMobile} ${styles.on}`
      } else {
        return `${styles.chart} ${styles.chartMobile}`
      }
    } else {
      return `${styles.chart} ${styles.chartPC}`
    }
  }

  return (
    <div className={getClassname()}>
      <div className={`${styles.viewTitle}`}>히스토리 차트</div>
      <div className="tab-type-round graph">
        <ul>
          <li>
            <a
              onClick={() => setChartCategory(CHART_CATEGORY.DONATION)}
              className={chartCategory === CHART_CATEGORY.DONATION ? 'active' : ''}
            >
              별풍선
            </a>
          </li>
          <li>
            <a
              onClick={() => setChartCategory(CHART_CATEGORY.CONCURRENT_VIEWER)}
              className={chartCategory === CHART_CATEGORY.CONCURRENT_VIEWER ? 'active' : ''}
            >
              동시 시청자
            </a>
          </li>
          <li>
            <a
              onClick={() => setChartCategory(CHART_CATEGORY.FAVOURITE)}
              className={chartCategory === CHART_CATEGORY.FAVOURITE ? 'active' : ''}
            >
              즐겨찾기
            </a>
          </li>
          <li>
            <a
              onClick={() => setChartCategory(CHART_CATEGORY.UP)}
              className={chartCategory === CHART_CATEGORY.UP ? 'active' : ''}
            >
              UP
            </a>
          </li>
          <li>
            <a
              onClick={() => setChartCategory(CHART_CATEGORY.SUBSCRIBERS)}
              className={chartCategory === CHART_CATEGORY.SUBSCRIBERS ? 'active' : ''}
            >
              구독자
            </a>
          </li>
        </ul>
      </div>
      <ECharts
        option={options}
        style={{ width: '100%', height: '400px' }}
        opts={{ renderer: 'svg' }}
        onEvents={{ dataZoom: handleDataZoom }}
      />
    </div>
  )
}
