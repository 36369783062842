import useUserStore from '@/stores/user'
import MyPageHeader from '../../mypage/MyPageHeader'
import { useRef, useState } from 'react'
import MyCommunityAside from './MyCommunityAside'
import { LIMIT } from '../shared/consts'
import { format } from 'date-fns'
import Paging from '@/components/elements/Paging'
import { Link } from 'react-router-dom'

export default function MyBoard() {
  const [page, setPage] = useState(1)
  const { user, fetch } = useUserStore()
  const lastPage = useRef(!user || user?.boards.length === 0 ? 1 : Math.ceil(user?.boards.length / LIMIT))

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <MyPageHeader user={user} fetch={fetch} />

      {user ? (
        <div className="history-wrap">
          <MyCommunityAside />
          <div className="history-conts">
            <div className="community-area" style={{ marginTop: '0' }}>
              <div className="community-box">
                <div className="main-header">
                  <span className="bolded-title">{user.boards.length.toLocaleString()}</span>
                  <span className="title">개의 내역이 있습니다.</span>
                </div>
                <div className="community-head">
                  <span>제목</span>
                  <span>닉네임</span>
                  <span>작성일</span>
                  <span>댓글수</span>
                  <span>추천수</span>
                </div>
                <div className="community-body">
                  <ul className="body">
                    {user.boards.length === 0 ? (
                      <li className="row list-null">게시글이 없습니다.</li>
                    ) : (
                      user.boards
                        .sort((b1: any, b2: any) => b2.id - b1.id)
                        .slice((page - 1) * LIMIT, page * LIMIT)
                        .map((board: any) => (
                          <li className="row" key={board.id}>
                            <div className="subject">
                              <div className="chip gray sm natural af">{board.category.name}</div>
                              {/* <div className="hidden">{board.id}</div> */}
                              <div className="post">
                                <Link to={`/community/boards/view/${board.id}`}>
                                  <span className="txt">{board.title}</span>
                                  <span className="img">
                                    {board.content.includes('<img') ? (
                                      <img src="/images/contents/ico_camera.svg" alt="images" />
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                  <span className="reply-cnt">
                                    {'[' + board.comments.filter((comment: any) => !comment.isDeleted).length + ']'}
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <span className="nickname">{user.nick}</span>
                            <span className="date">{format(new Date(board.createdAt), 'yyyy.MM.dd HH:mm')}</span>
                            <span className="reply">
                              {board.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
                            </span>
                            <span className="recommend">
                              <img src="/images/contents/ico_main_like.svg" alt="like" />
                              {board.likes.length.toLocaleString()}
                            </span>
                            {/* <span className="category">{'[' + board.category.name + ']'}</span> */}
                          </li>
                        ))
                    )}
                  </ul>
                </div>
              </div>
              <Paging page={page} setPage={setPage} lastPage={lastPage.current} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
