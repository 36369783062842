import { useState, useEffect, useContext } from 'react'
import styles from './header.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import useUserStore from '@/stores/user'
import { ModalPopup } from '../../popups/ModalPortal'
import { AlertModal } from '../../popups/Alert'
import { loginWithKakao } from '@/helpers/oauth/kakao.helper.ts'
import { loginWithGoogle } from '@/helpers/oauth/google.helper.ts'
import useBannerStore from '@/stores/banner'
import { signout } from '@/api/auth'
import { ThemeContext } from '@/context/ThemeContext'
import { Theme } from '@/helpers/theme'

export default function Header() {
  const { theme, setTheme } = useContext(ThemeContext)
  const { user, fetch } = useUserStore()
  const [isMobileMenu, setMobileMenu] = useState(false)
  const [windowResizing, setWindowResizing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  // const [confirmModal, setConfirmModal] = useState('ok')
  const banners = useBannerStore(state => state.banners)

  function MobileBanner() {
    const data = banners.filter((b: any) => b.placement === '모바일 배너')
    const index = Math.floor(Math.random() * data.length)
    const available = data && data.length > 0
    return (
      <a
        href={available ? data[index].url : 'https://uploads.poong.today/files/poongtoday_ad_0611.pdf'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={available ? data[index].image : 'https://uploads.poong.today/public/ad_mobile.jpg'}
          alt={'ad'}
          className={styles.mheaderBannerArea}
        />
      </a>
    )
  }

  function onMobileMenuOpen() {
    document.body.classList.add('scr-none')
    setMobileMenu(true)
  }

  const onMobileMenuClose = () => {
    document.body.classList.remove('scr-none')
    setMobileMenu(false)
  }

  const onMobileMenuClick = () => {
    document.body.classList.remove('scr-none')
    setMobileMenu(false)
  }

  function handleSubmit(e: any) {
    e.preventDefault()
    if (search.replace(/ /g, '') !== '') {
      navigate(`/search/results?search=${search.trim()}`)
      setSearch('')
    }
    return false
  }

  function handleChangeTheme() {
    const newTheme: Theme = theme === 'light' ? 'dark' : 'light'
    document.querySelector('html')?.setAttribute('data-theme', newTheme)
    localStorage.setItem('theme', newTheme)
    setTheme(newTheme)
  }

  useEffect(() => {
    let timeout: number
    const width: number = window.innerWidth
    const handleResize = () => {
      clearTimeout(timeout)
      setWindowResizing(true)

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 500)
    }
    window.addEventListener('resize', handleResize)

    if (width >= 1024) {
      onMobileMenuClose()
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowResizing])

  return (
    <div id="header" className={styles.header}>
      <div className={styles.headerArea}>
        <h1 className={styles.logo}>
          <Link to="/">
            <img src={`/images/common/${theme}/logo.svg`} alt="logo" />
          </Link>
        </h1>
        <div className={styles.gnb}>
          <ul className={styles.gnbArea}>
            <li>
              <Link
                to="/platform"
                className={window.location.pathname.split('/')[1] === 'platform' ? `${styles.active}` : ''}
              >
                플랫폼 통계
              </Link>
              {/* <Link to='/platform' className={styles.active}>플랫폼 랭킹</Link> */}
            </li>
            <li>
              <Link
                to="/rankings/broadcast"
                className={
                  window.location.pathname.split('/')[1] === 'broadcast' ||
                  (window.location.pathname.split('/')[2] && window.location.pathname.split('/')[2] === 'broadcast')
                    ? `${styles.active}`
                    : ''
                }
              >
                방송 랭킹
              </Link>
            </li>
            <li>
              <Link
                to="/rankings/donor"
                className={
                  window.location.pathname.split('/')[2] && window.location.pathname.split('/')[2] === 'donor'
                    ? `${styles.active}`
                    : ''
                }
              >
                큰손 랭킹
              </Link>
            </li>
            <li>
              <Link
                to="/community/boards"
                className={
                  window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === 'community'
                    ? `${styles.active}`
                    : ''
                }
              >
                커뮤니티
              </Link>
            </li>
            {/* <li>
              <Link to='/guide'>guide</Link>
            </li> */}
          </ul>
        </div>

        <div className={styles.headRight}>
          <ul>
            <li>
              <form className={`search-area ${styles.search}`} onSubmit={handleSubmit}>
                <input
                  type="search"
                  id="headSearch"
                  value={search}
                  name="headSearch"
                  placeholder="통합검색"
                  onChange={e => setSearch(e.target.value)}
                />
                <span
                  onClick={() => {
                    if (search.replace(/ /g, '') !== '') {
                      navigate(`/search/results?search=${search}`)
                      setSearch('')
                    }
                  }}
                  className={`ico-search ${theme === 'light' ? '' : theme} ${styles.icoSearch}`}
                ></span>
              </form>
            </li>
            <li>
              <Link to="/pointshop" className={styles.btnPoint}>
                POINT SHOP
              </Link>
            </li>
            <li>
              <button className={styles.btnTheme} onClick={handleChangeTheme}>
                <img src={`/images/contents/ico_${theme}.svg`} alt="theme" />
              </button>
            </li>
            <li>
              {user ? (
                <div className={styles.user}>
                  <button className={styles.img} onClick={() => navigate('/my/mypage')}>
                    <img src={user.picture} alt="user" />
                  </button>
                </div>
              ) : (
                <a className={styles.btnLogin} onClick={() => setShowModal(true)}>
                  로그인
                </a>
              )}
            </li>
            {/* <li>
              <Link to='/' className={styles.btnSignup}>회원가입</Link>
            </li> */}
          </ul>
        </div>

        <div className={styles.mobileGnb}>
          <ul>
            <li>
              <Link to="/search/results">
                <img src={`/images/common/${theme}/ico_search.svg`} alt="search" />
              </Link>
            </li>
            <li>
              <Link to="/pointshop" className={styles.btnPointM}>
                <img src={`/images/common/${theme}/ico_point.svg`} alt="point" />
              </Link>
            </li>
            <li>
              <button className={styles.btnTheme} onClick={handleChangeTheme}>
                <img src={`/images/contents/ico_${theme}.svg`} alt="theme" />
              </button>
            </li>
            <li>
              <button type="button" className={styles.btnGnb} onClick={onMobileMenuOpen}>
                <img src={`/images/common/${theme}/ico_menu.svg`} alt="btnGnb" />
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className={`${styles.mHeaderArea} ${isMobileMenu ? `${styles.on}` : ''}`}>
        <div className={styles.mHeader}>
          <div className={styles.close}>
            <span onClick={onMobileMenuClose}>&times;</span>
          </div>
          <div className={styles.loginBtnArea}>
            {user ? (
              <div
                className={styles.user}
                onClick={() => {
                  navigate('/my/mypage')
                  onMobileMenuClick()
                }}
              >
                <span className={styles.img}>
                  <img src={user.picture} alt="user" />
                </span>
                <span className={styles.nick}>
                  <span>{user.nick}</span>님<br />
                  환영합니다.
                </span>
              </div>
            ) : (
              <button type="button" className={styles.signin} onClick={() => setShowModal(true)}>
                로그인
              </button>
            )}
            {/* <button type="button" className={styles.signup}>
              회원가입
            </button> */}
          </div>

          <div className={styles.mGnb}>
            <ul
              onClick={() => {
                onMobileMenuClick()
              }}
            >
              <li>
                <Link
                  to="/platform"
                  className={
                    window.location.pathname.split('/')[1] === 'platform'
                      ? `${styles.active} ${theme === 'light' ? '' : styles.dark}`
                      : `${theme === 'light' ? '' : styles.dark}`
                  }
                >
                  플랫폼 통계
                </Link>
                {/* <Link to='/platform' className={styles.active}>플랫폼 랭킹</Link> */}
              </li>
              <li>
                <Link
                  to="/rankings/broadcast"
                  className={
                    window.location.pathname.split('/')[1] === 'broadcast' ||
                    (window.location.pathname.split('/')[2] && window.location.pathname.split('/')[2] === 'broadcast')
                      ? `${styles.active} ${theme === 'light' ? '' : styles.dark}`
                      : `${theme === 'light' ? '' : styles.dark}`
                  }
                >
                  방송 랭킹
                </Link>
              </li>
              <li>
                <Link
                  to="/rankings/donor"
                  className={
                    window.location.pathname.split('/')[2] && window.location.pathname.split('/')[2] === 'donor'
                      ? `${styles.active} ${theme === 'light' ? '' : styles.dark}`
                      : `${theme === 'light' ? '' : styles.dark}`
                  }
                >
                  큰손 랭킹
                </Link>
              </li>
              <li>
                <Link
                  to="/community/boards"
                  className={
                    window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === 'community'
                      ? `${styles.active} ${theme === 'light' ? '' : styles.dark}`
                      : `${theme === 'light' ? '' : styles.dark}`
                  }
                >
                  커뮤니티
                </Link>
              </li>
            </ul>
          </div>

          {MobileBanner()}

          {user ? (
            <div className={styles.logout}>
              <button
                type="button"
                onClick={() => {
                  signout().then(async () => {
                    await fetch()
                    window.location.href = '/'
                  })
                }}
              >
                로그아웃
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="로그인" onClose={setShowModal}>
            <div className="modal-login">
              <a id="kakao-login-btn" className="modal-login-img" onClick={loginWithKakao}>
                <img src="/images/contents/ico_kakao.png" alt="kakao" />
              </a>
              <a id="google-login-btn" className="modal-login-img" onClick={loginWithGoogle}>
                <img src="/images/contents/ico_google.png" alt="google" />
              </a>
            </div>
            <div className="modal-login-txt">
              로그인 절차를 진행하는 경우 서비스 이용에 필요한 개인정보 수집 및 활용에 대해 동의한 것으로 간주됩니다.
              자세한 정보는 개인정보처리방침을 참조바랍니다.
            </div>
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
