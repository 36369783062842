import styles from './main.module.scss'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import useAfreecaStore from '@/stores/afreeca'
import { getTopViewChannels } from '@/api/statistics'
import { useMemo } from 'react'

export default function TopViewRankings() {
  const { bjList } = useAfreecaStore()
  const { data: topChannels } = useQuery(['topViewChannels'], () => getTopViewChannels())
  const channels = useMemo(() => {
    if (!topChannels) return []
    return topChannels.map((channel: any) => {
      const foundBj = bjList.find((bj: any) => bj.i === channel.channelId)
      return {
        id: channel.channelId,
        name: foundBj?.n ?? channel.channelId,
      }
    })
  }, [topChannels, bjList])

  return (
    <div className={styles.searchTxt}>
      <span>인기 방송</span>
      <ul>
        {channels.map((channel: any, i: number) => {
          return (
            <li key={channel.id}>
              <Link to={`/broadcast/${channel.id}`} className="post">
                <span className={styles.popularTxt}>
                  {i + 1}. {channel.name}
                </span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
