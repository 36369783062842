import { Link } from 'react-router-dom'
import { useState } from 'react'
import useUserStore from '@/stores/user'
import commStyles from '../commBoard.module.scss'
import { ModalPopup } from '../../../popups/ModalPortal'
import { AlertModal } from '../../../popups/Alert'
import { loginWithKakao } from '@/helpers/oauth/kakao.helper.ts'
import { loginWithGoogle } from '@/helpers/oauth/google.helper.ts'

export default function BoardSignin({
  showShop,
  showWrite,
  notLoginText,
}: {
  showShop: boolean
  showWrite: boolean
  notLoginText: string
}) {
  const user = useUserStore(state => state.user)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className={commStyles.bbsLoginArea}>
      {user ? (
        <div className={commStyles.loginArea}>
          <div className={commStyles.loginAfter}>
            <span className={commStyles.loginTxt}>
              <span className={commStyles.nick}>
                {user.nick} 님<span>환영합니다.</span>
              </span>
              <span className={commStyles.point}>
                MY POINT
                <span className={commStyles.pointBox}>
                  <span>
                    {user.points.length === 0
                      ? 0
                      : user.points
                          .map((point: any) => point.points)
                          .reduce((p1: number, p2: number) => p1 + p2, 0)
                          .toLocaleString()}
                  </span>
                  {showShop ? (
                    <Link className={`chip gray ${commStyles.link}`} to="/pointshop">
                      POINT SHOP 가기
                    </Link>
                  ) : (
                    <Link to="/my/points" className={`chip gray ${commStyles.link}`}>
                      포인트 내역 보기
                    </Link>
                  )}
                </span>
              </span>
            </span>
            {showWrite ? (
              <div className={commStyles.bottomMenu}>
                <div className={commStyles.myMenu}>
                  <Link to="/community/my/boards" className={commStyles.myBtn}>
                    내 게시글
                    <p>{user.boards.length.toLocaleString()}</p>
                  </Link>
                  <Link to="/community/my/comments" className={commStyles.myBtn}>
                    내 댓글
                    <p>{user.comments.length.toLocaleString()}</p>
                  </Link>
                </div>
                <div className={commStyles.loginBtn}>
                  <Link to="/community/boards/add" className={commStyles.btnLogin}>
                    글쓰기
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className={commStyles.loginArea}>
          <span className={commStyles.loginTxt}>{notLoginText}</span>
          <button className={commStyles.btnLogin} onClick={() => setShowModal(true)}>
            로그인
          </button>
        </div>
      )}
      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="로그인" onClose={setShowModal}>
            <div className="modal-login">
              <a id="kakao-login-btn" className="modal-login-img" onClick={loginWithKakao}>
                <img src="/images/contents/ico_kakao.png" alt="kakao" />
              </a>
              <a id="google-login-btn" className="modal-login-img" onClick={loginWithGoogle}>
                <img src="/images/contents/ico_google.png" alt="google" />
              </a>
            </div>
            <div className="modal-login-txt">
              로그인 절차를 진행하는 경우 서비스 이용에 필요한 개인정보 수집 및 활용에 대해 동의한 것으로 간주됩니다.
              자세한 정보는 개인정보처리방침을 참조바랍니다.
            </div>
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
